import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'

import Layout from '../layouts/default/layout';

const R = () => {

  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)

  useEffect(()=>{
    setData(dataJSON)    
    rActive()
  }, [])

  const rActive = ()=>{
    let interval = setInterval(()=>{
        if(document.querySelector('ae-login-modal')){
            clearInterval(interval)
            

            // let rComponent = document.createElement('ae-change-forgot-password')
            // document.querySelector('.content-reset-password').appendChild(resetPasswordComponent)

            // document.querySelector('ae-change-forgot-password').show = true
        }
    }, 1000)
     
  }

  
  return (
    <>
      <title>{dataJSON.customer  ? dataJSON.customer.name + ' - Redirect' : ''}</title>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >
        <div className="">
          <div className="row">
            <div className="col-md-12 content-r mt-24 d-flex justify-content-center">
            <div className="lds-ring loading-revalidate">
            <p>
            Aguarde...
            </p>
            <div>
            </div>
            <div>
            </div>
            <div>
            </div>
            <div>
            </div>
            </div>

            </div>
          </div>


        </div>
      </Layout>

    </>
  )
}

export default R
